var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("v-combobox", {
    attrs: {
      items: _vm.items,
      label: _vm.label,
      "hide-selected": "",
      multiple: _vm.multiple || _vm.max > 1,
      hint: _vm.hint,
      "persistent-hint": _vm.showHint,
      outlined: "",
    },
    on: { input: _vm.limiter, change: _vm.handleChange },
    scopedSlots: _vm._u(
      [
        {
          key: "no-data",
          fn: function () {
            return [
              _c(
                "v-list-item",
                [
                  _c(
                    "v-list-item-content",
                    [
                      _c("v-list-item-title", [
                        _vm._v(
                          " A user with that email hasn't been created yet. Press "
                        ),
                        _c("kbd", [_vm._v("enter")]),
                        _vm._v(" to add this email as a placeholder. "),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]
          },
          proxy: true,
        },
        !_vm.noChips
          ? {
              key: "selection",
              fn: function ({ attrs, item, parent, selected }) {
                return [
                  _c(
                    "v-chip",
                    _vm._b(
                      {
                        attrs: {
                          "input-value": selected,
                          label: "",
                          draggable: "",
                        },
                      },
                      "v-chip",
                      attrs,
                      false
                    ),
                    [
                      _c("span", { staticClass: "pr-2" }, [
                        _vm._v(" " + _vm._s(item ? item.text : "") + " "),
                      ]),
                      _c(
                        "v-icon",
                        {
                          on: {
                            click: function ($event) {
                              return parent.selectItem(item)
                            },
                          },
                        },
                        [_vm._v(" $delete ")]
                      ),
                    ],
                    1
                  ),
                ]
              },
            }
          : null,
      ],
      null,
      true
    ),
    model: {
      value: _vm.displayUsers,
      callback: function ($$v) {
        _vm.displayUsers = $$v
      },
      expression: "displayUsers",
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }